<style scoped>
</style>

<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="合同名称：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>
                    <el-form-item label="合同起始时间：" prop="startDate">
                        <el-date-picker v-model="m.startDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="合同起始时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="合同结束时间：" prop="endDate">
                        <el-date-picker v-model="m.endDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="合同结束时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="甲方：" prop="firstName">
                        <el-input v-model="m.firstName"></el-input>
                    </el-form-item>
                    <el-form-item label="乙方：" prop="secondName">
                        <el-input v-model="m.secondName"></el-input>
                    </el-form-item>
                    <el-form-item label="合同内容">
                        <el-input type="textarea" rows="2" placeholder="合同内容" v-model="m.contact"></el-input>
                    </el-form-item>
                    <el-form-item label="项目id：" prop="projectId">
                        <el-input v-model="m.projectId"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编码：" prop="projectCode">
                        <el-input v-model="m.projectCode"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="ok('ruleForm')">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    //components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                name: [],
                startDate: [],
                endDate: [],
                firstName: [],
                secondName: [],
                contact: [],
                projectId: [],
                projectCode: [
                    {
                        min: 50,
                        max: 2147483647,
                        message: "长度在 50 到 2147483647 个字符",
                        trigger: "blur",
                    },
                ],
            },
            fileList: [],
        };
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data) {
                this.title = "修改 合同";

                this.m = data;
            } else {
                this.m = {
                    name: "",
                    startDate: "",
                    endDate: "",
                    firstName: "",
                    secondName: "",
                    contact: "",
                    projectId: 0,
                    projectCode: "",
                };
                this.title = "添加 合同";
            }
        },

        //提交合同信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sa.post("/contract/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    created() { },
};
</script>